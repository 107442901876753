import { SoftwarePackageV2 } from '@models/softwarePackge';
import StopWatch from '@images/SotwarePackages/stop-watch.svg';
import LockShield from '@images/SotwarePackages/lock-shield.svg';
import ExpandArrows from '@images/SotwarePackages/expand-arrows.svg';
import Seatbelt from '@images/SotwarePackages/seatbelt.svg';
import TrafficLight from '@images/SotwarePackages/traffic-light.svg';
import GearMagnifyingGlass from '@images/SotwarePackages/gear-magnifying-glass.svg';
import Lightbulb from '@images/SotwarePackages/lightbulb.svg';
import Leaf from '@images/SotwarePackages/leaf.svg';
import Warranty from '@images/SotwarePackages/warranty.svg';
import Wifi from '@images/SotwarePackages/wifi.svg';
import Device from '@images/SotwarePackages/device.svg';
import Scale from '@images/SotwarePackages/scale.svg';
import Usage from '@images/SotwarePackages/usage.svg';
import Support from '@images/SotwarePackages/support.svg';
import DemoFormBackground from '@images/SiteWideDemoForm/NL-NL.jpg';

export const nlSoftwarePackages: SoftwarePackageV2 = {
    version: 2,
    seo: {
        title: 'Softwarepakketten',
        description: 'Vergelijk functies en kies het gewenste plan.',
    },
    getPrices: 'Prijzen',
    demoFormBackgroundImage: DemoFormBackground,
    header: {
        title: 'Ons meest uitgebreide pakket, om aan uw behoeften te voldoen',
        description:
            'Geotab geeft uw wagenpark meer mogelijkheden door concrete resultaten te leveren die uw operationele doelen ondersteunen en waarde op lange termijn scheppen. Door gebruik te maken van diepgaande gegevensinzichten en AI, stellen wij u in staat geïnformeerde beslissingen te nemen om prestaties, veiligheid, duurzaamheid en kostenbeheer te verbeteren.  .',
        featured: [
            'Inzichten ondersteund door de grootste verzameling van verbonden voertuigen',
            'Een flexibel platform voor aanpassingen en schaalbaarheid',
            'Een veilig platform voor de beveiliging van uw gegevens',
            'Een gepatenteerd algoritme voor het opnemen van hoogwaardige gegevens',
            'AI en voorspellende analysemodellen',
            'Nonstop persoonlijke ondersteuning',
        ],
        cta: {
            bannerText: 'Profiteer van de volledige telematica-waarde met het GO-abonnement',
            title: 'GO Plan',
            subtitle: 'Wereldwijd',
            description:
                'Het GO Plan is ontworpen om verbeterde gegevens, uitgebreidere inzichten en uitgebreide compatibiliteit met een breder productassortiment te bieden. Het is een enkel plan dat maximale waarde biedt aan klanten.',
        },
    },
    goPlanBenefits: {
        title: 'Functies',
        subtitle: 'Krijg toegang tot elke functie van uw GO-apparaat met het GO-abonnement',
        description:
            'Elimineer de ingewikkeldheid van abonnementenbeheer en krijg uitgebreide toegang om beslissingen te nemen voor wagenparkbeheer en operationele efficiëntie.',
        features: [
            {
                title: 'Alles-in-één telematica-abonnement',
                description:
                    'Geen verschillende niveaus zodat u alles kunt ervaren wat het platform te bieden heeft. Geniet van een overzichtelijk abonnement met minder administratie en meer inzichten.',
                icon: Device,
            },
            {
                title: 'Onbeperkte schaalwaarde',
                description:
                    'Schaal moeiteloos uw gebruik van geavanceerde functies en gegevensinformatie naarmate uw bedrijf groeit.',
                icon: Scale,
            },
            {
                title: 'Tachograaf',
                description: 'Status en gegevens van de tachograaf in reële tijd (TAC). Beheer van inbreuken (AFA)',
                icon: Wifi,
            },
            {
                title: 'Leer terwijl uw gebruik groeit',
                description:
                    'Leer gemakkelijk het hele platform te beheersen. Krijg toegang tot begeleiding en tutorials binnen de app om de ROI van uw telematica-investering te maximaliseren.',
                icon: Usage,
            },
            {
                title: 'Toegang tot deskundigen',
                description:
                    'Uw bedrijf draait continu en dat geldt ook voor onze ondersteuning. Krijg toegang tot deskundigen 24 x 7 x 365.',
                icon: Support,
            },
            {
                title: 'Beperkte levenslange apparaatgarantie',
                description: 'Beschermt tegen de kosten van apparaatstoringen en biedt een zorgeloze garantie.',
                icon: Warranty,
            },
        ],
    },
    categories: [
        {
            title: 'Productiviteit',
            icon: StopWatch,
            features: [
                'Directe GPS-tracking met gepatenteerde, op curven gebaseerde algoritmen voor optimale registratie',
                'Zeer flexibele tool voor regels en uitzonderingen - aanpasbaar en bewerkbaar',
                'Rapportage bestuurdersamengroepering',
                'Dichtstbijzijnde voertuig lokaliseren op adres',
                'Bestuurders-ID via NFC',
                'Stuur berichten en routes naar bestuurders met compatibele Garmin-PND\'s',
            ],
        },
        {
            title: 'Veiligheid',
            icon: Seatbelt,
            features: [
                'Basiscoaching voor bestuurders: Akoestische waarschuwingen en meldingen snelheidsoverschrijdingen, stationaire loop, en identificatie van bestuurders',
                'Basiscoaching voor bestuurders en aanpasbare regels en uitzonderingen aan de serverzijde',
                'Geavanceerde coaching voor bestuurders: Akoestische waarschuwingen en meldingen voor ruw remmen, scherp bochten nemen, te hoge acceleratie, gebruik van de veiligheidsgordel, voertuig in achteruit, te hoge toerentallen',
                'Nieuwe detectie van grote en kleine botsingen',
                'Veiligheidscentrum: Voorspellende risicoanalyses en benchmarking',
            ],
        },
        {
            title: 'Optimalisatie van het wagenpark',
            icon: GearMagnifyingGlass,
            features: [
                'Herinneringen voor voertuigonderhoud gepland op basis van tijd of afstand',
                'Herinneringen voor voertuigonderhoud gepland op basis van kilometerstand of motoruren',
                'Eenvoudige apparaatinstallatie met zelfkalibrerende versnellingsmeter',
                'Motorstatusgegevens voor alle belangrijke motorprotocollen (brandstofverbruik, accuspanning, koelvloeistof, temperatuur)',
                'Storingsgegevens registreren en interpreteren (J1939, 1708, OBD, CAN)',
                'Add-In pechhulp Geotab Roadside voor gratis slepen, slotenmaker, accuboost, brandstoflevering, vervanging van lekke banden en nog meer',
                'Brandstofmanagementplatform - volgen van tankbeurten, aftappen van brandstof, brandstofdiefstal, integratie van brandstofpassen etc.',
            ],
        },
        {
            title: 'Uitbreidbaarheid',
            icon: ExpandArrows,
            features: [
                'Online software-rapportageplatform (SaaS) dat ondersteuning biedt voor een onbeperkt aantal voertuigen en gebruikers',
                'Ondersteuning voor meerdere kaarttypen',
                'Uitgebreide rapportagefunctionaliteit - bewerkbaar, aanpasbaar, trends',
                'Aanpasbare dashboards',
                'Flexibel toegangsbeheer voor gebruikers',
                'MyGeotab-toegang via Android en iOS',
                'SDK en API\'s die open, gratis en gebruiksvriendelijk zijn',
                'Toegang tot Add-ons en oplossingen van partners via Geotab Marketplace',
                'Beperkte levenslange apparaatgarantie',
                'Basisintegratie van IOX Add-ons (IOX-GOTALK, IOX_AUXM, IOX-BUZZ, IOX-NFCREADERA) met randapparatuur en apparaten in het voertuig',
                'Geavanceerde integratie van IOX Add-ons (IOX-BT, IOX-USB, IOX-CAN, IOX-WRKS en Add-ons voor controllers van substantieverspreiders IOX-RS232M/F/D) met randapparatuur en apparaten in het voertuig',
                'Actief traceren: Een premium trackingoplossing die locatie-informatie met een hogere frequentie en precisie levert',
            ],
        },
        {
            title: 'Naleving',
            icon: TrafficLight,
            features: [
                'Detectie van apparaatmanipulatie',
                'App voor bestuurders (Geotab Drive) schaalbaar voor toekomstige behoeften',
                'Diensturen-naleving via Geotab Drive (Android/iOS) en Garmin',
                'DVIR-conformiteit via Geotab Drive (Android/iOS)',
                'IFTA-registratie van kilometrage',
                'Persoonlijke modus',
            ],
        },
        {
            title: 'Duurzaamheid',
            icon: Leaf,
            features: [
                'Beoordeling geschiktheid EV (EVSA)',
                'Infrastructuurbeoordeling voor EV (EVIA)',
                'Gebruikte EV-rijenergie',
                'EV-acculading in in percentage (SOC)',
                'EV-laadstatus',
                'EV-laadenergie',
                'Locatie van het oplaadnetwerk voor EV\'s',
            ],
        },
        {
            title: 'Innovatie',
            icon: Lightbulb,
            features: ['Generatieve AI', 'Routering en optimalisering', 'Geotab Data Connector'],
        },
        {
            title: 'Privacy en beveiliging',
            icon: LockShield,
            features: [
                'Een verantwoordelijk kader voor privacygerichte resultaten',
                'FIPS 140-2 validatie',
                'Fed Ramp toestemming',
                'ISO-IEC 27001:2013',
                'UNECE 155/156',
                'Vertrouwd door veel van de meest veiligheidsbewuste organisaties wereldwijd',
            ],
        },
    ],
};
